document.querySelectorAll('.navbar-nav > .dropdown').forEach(dropdown => {
  const toggle = dropdown.querySelector('.dropdown-toggle')
  const menu = dropdown.querySelector('.dropdown-menu')

  dropdown.addEventListener('mouseover', () => {
    toggle.click()
  })

  dropdown.addEventListener('mouseout', () => {
    toggle.classList.remove('show')
    menu.classList.remove('show')
  })
})
